/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import Blockquote from '../components/blockquote';
import ClientList from '../components/client-list';
import Form from '../components/form';
import Hero from '../components/hero';
import Layout from '../components/layout';
import Section from '../components/section';
import SEO from '../components/seo';
import ButtonGroup from '../components/button-group';
import Button from '../components/button';


const NFTConsultantUKPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={ location }
            hideHeader
        >
            <SEO
                title="NFT Consultant UK"
                description="I work with clients to make their NFT projects a success. From strategy to implementation to iteration."
            />
            <Hero
                imageName="tom-hirst-headshot.png"
                imageAlt="Tom Hirst likes the NFTs"
                imageCaption="Tom Hirst likes the NFTs."
                noHeader
                standardHeight
            >
                <h1>NFT Consultant UK</h1>
                <p className="lead">I&apos;m a UK based NFT consultant and strategist helping businesses capture additional value from their most loyal customers.</p>
                <p>Are you looking for:</p>
                <ul className="check-list">
                    <li>
                        <FontAwesomeIcon icon="check" />
                        Someone to explain how your business can leverage NFTs?
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        A strategic go-to-market plan for your upcoming NFT project?
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        Someone with the connections to build and launch an NFT project for you?
                    </li>
                    <li>
                        <FontAwesomeIcon icon="check" />
                        An audit of your existing NFT project to identify blind spots and fix them?
                    </li>
                </ul>
                <p>
                    You&apos;re in the right place.
                </p>
                <ButtonGroup>
                    <Button to="#hire-me" icon="arrow-right" text="Hire me today" color="green" anchor />
                    <Button to="#read-more" icon="arrow-down" text="Read more about me" anchor />
                </ButtonGroup>
            </Hero>
            <Section narrow>
                <h2 id="read-more">Why does my business need NFTs?</h2>
                <p>NFTs introduce scarcity and ownership to digital assets providing a pathway to the monetisation of your brand affinity in new and exciting ways.</p>
                <p>Your business has built non-tangible good will over time that you can capitalise on with NFT technology. You could be leaving money on the table if you don&apos;t consider what value NFTs can provide to your customers.</p>
                <p>The NFT playbook is being written as we speak. If you own intellectual property you can tokenise it.</p>
                <p>If you want to:</p>
                <ul>
                    <li>Reward your customers in a cost effective way with digital assets</li>
                    <li>Capture additional value from your most loyal fans</li>
                    <li>Become an industry leader by adopting NFTs early</li>
                    <li>Gate content and/or sales behind token ownership</li>
                    <li>Build further brand affinity through digital art</li>
                </ul>
                <p>I can provide actionable insights your business can start using today.</p>
                <h2>What does Tom Hirst know about being an NFT Consultant?</h2>
                <p>I&apos;m a developer with my finger on the pulse of cutting edge technology and a consumer with my finger on the pulse of culture. I&apos;m a clear thinker, strategic planner, programmer, marketer and writer.</p>
                <p>I like building and launching projects end-to-end. From strategy, to implementation to iteration. Having owned a <a href="https://www.tomhirst.com/how-to-start-a-shopify-side-business-and-sell-it/">clothing line business</a>, alongside running a <a href="https://www.tomhirst.com/wordpress-consultant/">web development consultancy</a> for 12 years, my unique history proves relevant in the web3 world of NFTs.</p>
                <p>I&apos;m an active collector of NFT art and I&apos;m a member of influential communities and DAOs within the space. This makes me a good fit to be your NFT consultant, if you&apos;re in the UK or beyond.</p>
                <p>In short, I know what makes a good NFT project and I can help you make one.</p>
                <ul>
                    <li>
                        <a href="https://www.tomhirst.com/getting-started-with-nfts">Here&apos;s an in-depth article I wrote about getting started with NFTs</a>
                    </li>
                    <li>
                        <a href="https://github.com/tomhirst">Check out the Solidity NFT smart contracts I&apos;ve written on GitHub</a>
                    </li>
                    <li>
                        <a href="https://opensea.io/tom_hirst_vault">View some of the NFTs that I own on OpenSea</a>
                    </li>
                </ul>
                <h2>What are some example NFT projects my business could run?</h2>
                <p>With an NFT consultant, you could run:</p>
                <ul>
                    <li>
                        A set of 10,000 randomly generated on-brand avatars for fans to use on social media
                    </li>
                    <li>
                        A collection of art pieces with significance to your business' story
                    </li>
                    <li>
                        A digitally ticketed access to an event your business plans to run
                    </li>
                    <li>
                        A loyalty programme where tokens are distributed to members as rewards
                    </li>
                    <li>
                        A community that requires ownership of a branded NFT for access
                    </li>
                </ul>
                <p>If any of these suggestions sound useful, or you have a specific idea, <a href="#hire-me">get in touch</a> so we can discuss together.</p>
                <h2 id="hire-me">Ready make your NFT project a success?</h2>
                <p>
                    I&apos;d love to consult with you on your NFT project.
                    Fill out the form with a few details and I&apos;ll get back to you with some initial advice:
                </p>
                <Form
                    id="contact-form"
                    contact
                    buttonAsideText="I'll reply as soon as I can!"
                    messagePlaceholder="Hi Tom, I have an NFT project needing expert attention."
                    pathname={ location && location.pathname }
                    budgetLabel=" "
                />
                <h2>12 years in the digital sector</h2>
                <p>I&apos;ve worked on projects for some great names with great people:</p>
                <ClientList />
                <h2>At the cutting edge, yet down to earth</h2>
                <p>My clients trust my expertise and find me easy to work with:</p>
                <div className="blockquote-list-with-middle">
                    <Blockquote
                        quote="We have appreciated his technical advice. I can really recommend working with Tom."
                        cite="Henning Hovland, CTO at Dekode"
                        imageName="hh-headshot.jpg"
                        imageAlt="Henning Hovland"
                        mediumQuote
                        boxed
                    />
                    <Blockquote
                        quote="Tom is structured, accountable, timely, stable and easy to talk to. I would recommend working with him to anyone."
                        cite="Hillevi Røstad, CAO at Dekode"
                        imageName="hr-headshot.jpg"
                        imageAlt="Hillevi Røstad"
                        boxed
                    />
                </div>
                <h2>Ready to start moving your NFT project forward with a consultation?</h2>
                <p>We can get the ball rolling <strong>today</strong>.</p>
                <a className="button is-green has-icon" href="#hire-me">
                    Let&apos;s go!
                    <FontAwesomeIcon icon="arrow-up" />
                </a>
            </Section>
        </Layout>
    );
};

NFTConsultantUKPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

NFTConsultantUKPage.defaultProps = {
    location: {},
};

export default NFTConsultantUKPage;
